import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Box, Flex, Text, Button } from 'rebass/styled-components';
import { Tag } from '@getro/rombo';
import NextLink from 'next/link';

import PropTypes from 'prop-types';
import { Heart, X } from 'lucide-react';
import { toggleIntroductionRequestModal } from '../../../redux/actions/introductionRequestActions';
import { DeletedOverlay } from '../deletedOverlay';
import usePreviousPageQueryParams from '../../../hooks/usePreviousPageQueryParams';
import { makeOrganizationAlreadyIntroduced, makeOrganizationIntroductionStatus } from '../../../redux/selectors/user';
import { companyStageFormatter, mapHeadCountToInterval } from '../../../helpers/stringHelper';
import { NetworkSchema } from '../../../schema/network';
import useTagsVisibility from '../../../hooks/useTagsVisibility';
import { ProfilePicture } from '../../atoms/profilePicture';

export const CompanyListItem = ({
  network,
  canDiscard,
  canFavorite,
  onDiscard,
  onUndoDiscard,
  onFavorite,
  isFavorite,
  isDiscarded,
  company,
  isTalentNetworkEnabled,
  isUserVerified,
  showDiscardedOverlay,
  organizationString,
  ...rebassProps
}) => {
  const alreadyIntroduced = useSelector(makeOrganizationAlreadyIntroduced(company));
  const introductionStatusAccepted = useSelector(makeOrganizationIntroductionStatus(company)) === 'accepted';
  const dispatch = useDispatch();
  const query = usePreviousPageQueryParams();
  const { hasIndustryTagsVisible, hasCompanySizeTagsVisible, hasStageTagsVisible, visibleTopics } = useTagsVisibility({
    network,
    company,
  });

  const requestIntroduction = (e) => {
    e.preventDefault();
    dispatch(toggleIntroductionRequestModal({ organization: company, source: 'organizations_browser' }));
  };

  const hasTags = useMemo(
    () => visibleTopics.length > 0 || hasIndustryTagsVisible || hasCompanySizeTagsVisible || hasStageTagsVisible,
    [hasCompanySizeTagsVisible, hasStageTagsVisible, visibleTopics, hasIndustryTagsVisible],
  );

  return (
    <Card
      tx="card"
      variant="listItem"
      sx={{
        boxShadow: 'xs',
        my: [0, 0],
      }}
      p={0}
      m={0}
      width={[1]}
      display="flex"
      flexDirection="column"
      className={`organization-card ${showDiscardedOverlay && isDiscarded ? 'discarded' : ''}`}
      data-testid="company-list-item"
      {...rebassProps}
    >
      <DeletedOverlay
        onUndoClick={onUndoDiscard || onDiscard}
        discardedMessage={<>We won&rsquo;t show you this {organizationString} anymore</>}
        showDiscardedOverlay={showDiscardedOverlay}
        isDiscarded={isDiscarded}
      />
      <Flex
        flexGrow={1}
        width={1}
        flexDirection="column"
        sx={{
          transition: 'all ease .3s',
          filter: showDiscardedOverlay && isDiscarded ? 'blur(2px) grayscale(100%)' : '',
          opacity: showDiscardedOverlay && isDiscarded ? 0.3 : 1,
        }}
      >
        <NextLink
          shallow
          passHref
          href={{ pathname: `/companies/${company.slug}`, hash: '#content', query }}
          className="organization"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            height: '100%',
          }}
        >
          <Flex flexDirection="column" flexGrow="1" sx={{ position: 'relative', height: '100%' }}>
            <Flex p={3} pb={2} data-testid="header">
              <Box width="70px" height="70px" flexShrink="0">
                <ProfilePicture
                  imageWidth={70}
                  imageHeight={70}
                  name={company.name}
                  variant="square"
                  imageUrl={company.logoUrl}
                />
              </Box>

              <Flex pl={3} flexDirection="column" flex="1 1 auto">
                <Button
                  as="a"
                  className="theme_only"
                  textAlign="left"
                  justifyContent="flex-start"
                  variant="getroLink"
                  color="text.dark"
                  sx={{ lineHeight: 1.2, cursor: 'pointer', whiteSpace: 'unset', ':hover': { textDecoration: 'none' } }}
                  fontSize={4}
                  type="button"
                >
                  {company.name}
                </Button>
                {company.activeJobsCount > 0 && (
                  <Text fontSize={1}>{company.activeJobsCount.toLocaleString('en-US')} jobs</Text>
                )}
              </Flex>
            </Flex>
            <Flex px={3} pb={2} data-testid="body" flexDirection="column" flex="1 1 auto">
              {hasTags && (
                <Flex sx={{ mb: '8px', rowGap: '4px', columnGap: '4px', flexWrap: 'wrap', '& > div': { mr: '0' } }}>
                  {hasIndustryTagsVisible && (
                    <>
                      {company.industryTags
                        .filter((tag) => tag.toLowerCase() !== 'information technology')
                        .map((tag) => (
                          <Tag key={tag} variant="transparent">
                            {tag}
                          </Tag>
                        ))}
                    </>
                  )}
                  {hasCompanySizeTagsVisible && (
                    <Tag key={company.headCount} variant="transparent">
                      {`${mapHeadCountToInterval(company.headCount)} employees`}
                    </Tag>
                  )}
                  {hasStageTagsVisible && companyStageFormatter(company.stage) !== 'Other' && (
                    <Tag key={company.stage} variant="transparent">
                      {companyStageFormatter(company.stage)}
                    </Tag>
                  )}
                  {visibleTopics.map((tag) => (
                    <Tag key={tag} variant="transparent">
                      {tag}
                    </Tag>
                  ))}
                </Flex>
              )}
              <Box pb={3} sx={{ maxHeight: '100px' }}>
                <Text
                  as="p"
                  variant="clamp"
                  sx={{
                    lineHeight: '1.4',
                    color: 'text.main',
                    height: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {company.description}
                </Text>
              </Box>

              {isTalentNetworkEnabled && isUserVerified && (
                <Box
                  py={3}
                  sx={{
                    textAlign: 'center',
                    borderTop: '1px dashed #e5e5e5',
                  }}
                >
                  {!alreadyIntroduced && (
                    <>
                      <Text
                        color="text.main"
                        fontSize={0}
                        sx={{
                          strong: {
                            fontWeight: 'medium',
                          },
                        }}
                      >
                        You can request an introduction to <strong>{company.name}</strong>
                      </Text>
                      <Button
                        type="button"
                        variant="secondary"
                        fontSize={1}
                        py={2}
                        mt={2}
                        mx="auto"
                        onClick={requestIntroduction}
                        data-testid="request-introduction-button"
                        sx={{ zIndex: 2, position: 'relative' }}
                      >
                        Request introduction
                      </Button>
                    </>
                  )}
                  {alreadyIntroduced && (
                    <Text as="h4" fontSize={0} color="text.main" mb={0}>
                      {introductionStatusAccepted ? 'Introduction has been made' : 'Introduction Request sent'}
                    </Text>
                  )}
                </Box>
              )}
            </Flex>
          </Flex>
        </NextLink>

        {(canDiscard || canFavorite) && (
          <Flex
            sx={{
              borderTop: 1,
              borderColor: 'neutral.50',
              button: {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
            width={1}
            data-testid="footer"
          >
            {canDiscard && (
              <Button
                aria-checked={isDiscarded}
                onClick={onDiscard}
                title={isDiscarded ? 'Undo Discard' : 'Discard'}
                color="neutral.400"
                flexGrow={1}
                variant="companyListItemAction"
              >
                <Box
                  mr={1}
                  variant="icon"
                  className="fa fa-close-o"
                  height="14px"
                  width="14px"
                  strokeWidth="2"
                  aria-hidden="true"
                  as={X}
                />
                {isDiscarded ? 'Undo Discard' : 'Discard'}
              </Button>
            )}
            {canFavorite && (
              <Button
                aria-checked={isFavorite}
                onClick={onFavorite}
                title={isFavorite ? 'Undo Favorite' : 'Favorite'}
                color="customPrimary"
                flexGrow={1}
                variant="companyListItemAction"
              >
                <Box
                  mr={1}
                  variant="icon"
                  fill={isFavorite ? 'currentColor' : 'none'}
                  className="fa fa-heart-o"
                  height="14px"
                  width="14px"
                  strokeWidth="2"
                  aria-hidden="true"
                  as={Heart}
                />
                {isFavorite ? 'Undo Favorite' : 'Favorite'}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

CompanyListItem.propTypes = {
  network: NetworkSchema.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string),
    logoUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    industryTags: PropTypes.arrayOf(PropTypes.string),
    topics: PropTypes.arrayOf(PropTypes.string),
    activeJobsCount: PropTypes.number.isRequired,
    headCount: PropTypes.number,
    stage: PropTypes.string,
  }).isRequired,
  showDiscardedOverlay: PropTypes.bool,
  onFavorite: PropTypes.func,
  onDiscard: PropTypes.func,
  onUndoDiscard: PropTypes.func,
  canFavorite: PropTypes.bool,
  canDiscard: PropTypes.bool,
  isDiscarded: PropTypes.bool,
  isFavorite: PropTypes.bool,
  isTalentNetworkEnabled: PropTypes.bool,
  isUserVerified: PropTypes.bool,
  organizationString: PropTypes.string.isRequired,
};

CompanyListItem.defaultProps = {
  showDiscardedOverlay: false,
  isTalentNetworkEnabled: false,
  isUserVerified: false,
  isFavorite: false,
  isDiscarded: false,
  canFavorite: true,
  canDiscard: true,
  onDiscard: () => {},
  onUndoDiscard: null,
  onFavorite: () => {},
};
